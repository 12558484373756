import { Injectable } from '@angular/core';
import { AbstractService } from '../abstract.service';

export interface UserActivity {
  id?: number;
  name: string;
  details?: any;
  parentId: number | null;
  leaf?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class UserActivity extends AbstractService<UserActivity> {
  override serviceName(): string {
    return 'user-activities';
  }
}
