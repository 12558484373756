import { JsonPipe, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, signal, Signal, viewChild, WritableSignal } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { RouterLinkWithHref } from '@angular/router';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SubChapter, SubChapterService } from 'src/app/subChapter.service';
import { environment } from 'src/environments/environment';
import { S3Service } from 'src/app/s3.service';

@Component({
  selector: 'app-question-set-add',
  templateUrl: './sub-chapter-add.component.html',
  styleUrls: ['./sub-chapter-add.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule,
    NgIf,
    NgFor
  ],
})
export class SubChapterAddComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: { id: number },
    private dialogRef: MatDialogRef<SubChapterAddComponent>,
    private subChapterService: SubChapterService,
    private toast: ToastrService,
    private s3Service: S3Service,
  ) {}
  id: number | undefined;
  destroy$ = new Subject<any>();
  questionGListChanges = new Subject<void>();
  questionSetForm = new FormGroup({
    title: new FormControl(''),
    details: new FormControl(''),
    questionGroupId: new FormControl(''),
    numOfShortQuestions: new FormControl(-1),
    numOfLongQuestions: new FormControl(-1),
  });
  file: File | null = null;
  files: File[] = []; // Array to store files
  readonly imgFileUpload: Signal<ElementRef> = viewChild.required('imgUpload');
  readonly imgUrls: WritableSignal<string[]> = signal([]); // Array to store image URLs
  readonly imgUrl: WritableSignal<null | string> = signal(null);
  readonly s3Urls: WritableSignal<string[]> = signal([]); // Array to store S3 image URLs
  // selectedFile: File | null = null;
  s3BucketUrl!: string | '';
  fileUrl: string | null = null;
  openImgFileUpload() {
    this.imgFileUpload().nativeElement.click();
  }
 fileUpload(index: number){
    this.s3Service.uploadFile(this.files[index], 'notes/'+this.id+'/'+this.files[index].name);
    this.s3BucketUrl = environment.s3Url + environment.s3Object+ 'notes/'+this.id+'/'+ `${this.id}-${index}.png`;
  }
  uploadToS3(file: File): string {
    // Replace this mock implementation with your S3 upload logic
    const s3BucketUrl = environment.s3Url + 'notes'+this.id;
    const uniqueFileName = `${this.id}-${file.name}`;
    return `${s3BucketUrl}${uniqueFileName}`;
  }
  removeImage(index: number) {
    this.imgUrls.update((urls) => {
      const updatedUrls = [...urls];
      updatedUrls.splice(index, 1);
      return updatedUrls;
    });
    this.s3Urls.update((urls) => {
      const updatedUrls = [...urls];
      updatedUrls.splice(index, 1);
      return updatedUrls;
    });
    this.files.splice(index, 1); // Remove the file at the same index
  }
  onImageSelected(event: Event) {
    const el = event.target as HTMLInputElement;
    if (el && el.files) {
      const file = el.files[0];
      const fileSizeMB = file.size / (1024 * 1024); // Size in MB
      // if (fileSizeMB > 10) {
      //   this.toast.warning('Max file Size allowed is 10mb');
      //   return;
      // }
      this.convertToBlob(file, (blob) => {
        this.files.push(file); // Add the file to the files array
        const imageUrl = URL.createObjectURL(blob); // Generate local image URL
        this.imgUrls.update((urls) => [...urls, imageUrl]); // Update local preview URLs

        // Simulate S3 upload (replace with actual S3 upload logic)
       const s3Url = this.uploadToS3(file); 
        this.s3Urls.update((urls) => [...urls, s3Url]); // Update S3 URLs
      });
    
  
      
    }
    
  }
  convertToBlob = (file: File, cb: (blob: Blob) => void) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target == null) return;
      let blob: Blob;
      if (typeof event.target.result === 'string') {
        // Handle the case when event.target.result is a string
        blob = new Blob([event.target.result], { type: file.type });
      } else if (event.target.result instanceof ArrayBuffer) {
        // Handle the case when event.target.result is an ArrayBuffer
        blob = new Blob([event.target.result], { type: file.type });
      } else {
        // Handle the case when event.target.result is null or an unexpected type
        console.error(
          'Unexpected data type from FileReader:',
          typeof event.target.result
        );
        return;
      }
      cb(blob);
    };
    reader.readAsArrayBuffer(file);
  };
  ngOnInit(): void {}
  submit() {
    const question = this.questionSetForm.value;
    const questionG: SubChapter = {
      title: question.title ?? '',
      details: question.details ?? '',
      questionGroupId: this._data.id ?? 0,
      numOfLongQuestions: question.numOfLongQuestions ?? -1,
      numOfShortQuestions: question.numOfShortQuestions ?? -1,
    };
    this.subChapterService.create(questionG).subscribe((res: any) => {
      this.toast.success('Sub Chapter Added successfully');
      this.dialogRef.close();
    });
  }
}
