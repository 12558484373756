import { Component, ElementRef, OnInit, Signal, WritableSignal, computed, signal, viewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { QuestionGroup } from '../../questions.model';
import { QuestionGroupService } from '../questions.service';
import { Subject, map, switchMap, tap } from 'rxjs';
import { CommonModule, JsonPipe, Location } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterLinkWithHref } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatSelectModule } from '@angular/material/select';
import { AppService, Branch } from 'src/app/app.service';

@Component({
  selector: 'app-question-group-edit',
  templateUrl: './question-group-edit.component.html',
  styleUrls: ['./question-group-edit.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule,
    MatSelectModule,
  ],
})
export class QuestionGroupEditComponent implements OnInit {
  constructor(
    private questionService: QuestionGroupService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private location: Location
  ) {}
  questionGroup$: any;
  id: number | undefined;

  ngOnInit(): void {
    this.questionGroup$ = this.route.params.pipe(
      map((params) => params['id']),
      tap((id) => (this.id = id)),
      switchMap((id) => this.questionService.getById(id)),
      tap((question) => this.questionForm.patchValue(question as any))
    );
  }
  onImageSelected(event: Event) {
    const el = event.target as HTMLInputElement;
    if (el && el.files) {
      const file = el.files[0];
      const fileSizeMB = file.size / (1024 * 1024); // Size in MB
      // if (fileSizeMB > 10) {
      //   this.toast.warning('Max file Size allowed is 10mb');
      //   return;
      // }

      this.convertToBlob(file, (blob) => {
        this.file = file;
        const imageUrl = URL.createObjectURL(blob);
        this.imgUrl.set(imageUrl);
      });
    }
  }
  file: File | null = null;
  readonly imgFileUpload: Signal<ElementRef> = viewChild.required('imgUpload');
  readonly imgUrl: WritableSignal<null | string> = signal(null);
  openImgFileUpload() {
    this.imgFileUpload().nativeElement.click();
  }
    convertToBlob = (file: File, cb: (blob: Blob) => void) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target == null) return;
      let blob: Blob;
      if (typeof event.target.result === 'string') {
        // Handle the case when event.target.result is a string
        blob = new Blob([event.target.result], { type: file.type });
      } else if (event.target.result instanceof ArrayBuffer) {
        // Handle the case when event.target.result is an ArrayBuffer
        blob = new Blob([event.target.result], { type: file.type });
      } else {
        // Handle the case when event.target.result is null or an unexpected type
        console.error(
          'Unexpected data type from FileReader:',
          typeof event.target.result
        );
        return;
      }
      cb(blob);
    };
    reader.readAsArrayBuffer(file);
  };
  questionGListChanges = new Subject<void>();
  questionForm = new FormGroup({
    title: new FormControl(''),
    details: new FormControl(''),
    numOfShortQuestions: new FormControl(-1),
    numOfLongQuestions: new FormControl(-1),
  });

  submit() {
    const question = this.questionForm.value;
    const questionG: QuestionGroup = {
      title: question.title ?? '',
      details: question.details ?? '',
      numOfLongQuestions: question.numOfLongQuestions ?? 0,
      numOfShortQuestions: question.numOfShortQuestions ?? 0,
    };
    this.questionService.update(this.id!, questionG).subscribe((res: any) => {
      this.toastrService.success('Edited successfully', '', { timeOut: 3000 });
      this.location.back();
    });
  }
}
