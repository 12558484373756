import { CdkTableDataSourceInput } from '@angular/cdk/table';
import { Component, DestroyRef, ViewChild, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteModule,
} from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatTable, MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import {
  Observable,
  Subject,
  debounceTime,
  filter,
  map,
  merge,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { User, UserService } from '../user.service';
import { Subscription, SubscriptionService } from '../subscription.service';
import { FilterDTO } from '../app.model';
import { UtcToLocalPipe } from '../coin-transactions/utc-to-local.pipe';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getRoleFromKc } from '../Utils';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-subscription-list',
  standalone: true,
  imports: [
    RouterLink,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatTableModule,
    MatPaginator,
    UtcToLocalPipe,
    AsyncPipe,
    MatInputModule,
    FormsModule,
    NgIf,
  ],
  templateUrl: './subscription-list.component.html',
  styleUrl: './subscription-list.component.scss',
})
export class SubscriptionListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  readonly subscriptionService = inject(SubscriptionService);
  readonly subscriptionTypeCtrl = new FormControl('ALL');
  readonly kc = inject(KeycloakService);
  readonly role = getRoleFromKc(this.kc);
  readonly toast = inject(ToastrService);
  subscriptions$!: Observable<CdkTableDataSourceInput<Subscription>>;
  totalElements = 0;
  readonly displayedColumns = [
    'id',
    'email',
    'cost',
    'startDate',
    'validDays',
    'level',
  ];
  readonly userCtrl = new FormControl<User | null>(null);
  readonly userService = inject(UserService);
  readonly search$ = new Subject<void>();
  readonly destroyRef = inject(DestroyRef);
  users$!: Observable<User[]> | undefined;
  ngOnInit(): void {
    this.users$ = this.userCtrl?.valueChanges.pipe(
      debounceTime(500),
      filter((s) => !!s),
      switchMap((s) => {
        const filter: FilterDTO = {
          pageNumber: 1,
          pageSize: 10,
          search: `email:${s}*`,
        };
        return this.userService.filterData(filter).pipe(map((p) => p.elements));
      })
    );
    this.subscriptions$ = merge(
      this.paginator.page,
      this.search$.asObservable()
    ).pipe(
      startWith({}),
      switchMap(() => {
        let search = '';
        const type = this.subscriptionTypeCtrl.value;
        const user = this.userCtrl.value;
        if (type !== 'ALL') {
          search = `level:${type}`;
        }
        if (user) {
          if (search?.length > 0) {
            search += ' AND ';
          }
          search += `user.id:${user.id}`;
        }
        const f: FilterDTO = {
          pageNumber: this.paginator.pageIndex + 1,
          pageSize: this.paginator.pageSize,
          search: search,
          sorts: [
            {
              key: 'startDate',
              direction: 'DESC',
            },
          ],
        };
        return this.subscriptionService.filterData(f).pipe(
          tap((p) => (this.totalElements = p.totalElements)),
          map((p) => p.elements)
        );
      })
    );
  }

  displayFn(user: any): string {
    return user && user.email ? user.email : '';
  }

  purchase() {
    const level = this.subscriptionTypeCtrl.value;
    const user = this.userCtrl.value;
    if (!level || level === 'ALL') {
      this.toast.error('Please select another subscription package');
      return;
    }
    if (!user) {
      this.toast.error('Please select user');
      return;
    }

    const confirmed = confirm(
      'Are you sure you want to purchase the subscription for user'
    );
    if (!confirmed) return;

    this.subscriptionService
      .create({
        userId: user.id,
        level: level,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.toast.success('Bought subscription');
        this.search$.next();
      });
  }
}
