<h1>User Activity</h1>

<div class="flex gap-4">


  <mat-form-field class="w-[300px]">
    <mat-label>User</mat-label>
    <input
      type="text"
      placeholder="Enter user email"
      matInput
      [formControl]="userCtrl"
      [matAutocomplete]="auto"
      class="min-w-[500px]"
    />
    <mat-autocomplete #auto="matAutocomplete">
      @for (user of users$ | async; track user.id) {
      <mat-option [value]="user.email">{{ user.email }}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field class="w-[300px]">
    <mat-label>Activity </mat-label>
    <input
      type="text"
      placeholder="Enter user email"
      matInput
      [formControl]="activityCtrl"
      [matAutocomplete]="auto"
      class="min-w-[500px]"
    />
 
  </mat-form-field>
  <button (click)="search$.next()">Search</button>

</div>
<table
  mat-table
  [dataSource]="(userActivity$ | async) ?? []"
  class="mat-elevation-z8"
>
  <ng-container matColumnDef="userId">
    <th mat-header-cell *matHeaderCellDef>Id.</th>
    <td mat-cell *matCellDef="let element">{{ element.userId }}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="activityType">
    <th mat-header-cell *matHeaderCellDef>Activity Type</th>
    <td mat-cell *matCellDef="let element">{{ element.activityType }}</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="device">
    <th mat-header-cell *matHeaderCellDef>Device</th>
    <td mat-cell *matCellDef="let element">{{ element.device }}</td>
  </ng-container>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">{{ element.localDateTime | utcToLocal }}</td>
  </ng-container>
  <ng-container matColumnDef="ip">
    <th mat-header-cell *matHeaderCellDef>Ip</th>
    <td mat-cell *matCellDef="let element">{{ element.ip }}</td>
  </ng-container>
  <ng-container matColumnDef="details">
    <th mat-header-cell *matHeaderCellDef>Details</th>
    <td mat-cell *matCellDef="let element">{{ element.details }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  #paginator
  class="demo-paginator"
  [length]="totalElements"
  [pageSize]="20"
  aria-label="Select page"
>
</mat-paginator>
