import { Component, ElementRef, Inject, signal, Signal, viewChild, WritableSignal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Unit, UnitService } from '../unit.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgIf } from '@angular/common';
import { environment } from 'src/environments/environment';
import { S3Service } from '../s3.service';

@Component({
  selector: 'app-unit-edit',
  standalone: true,
  imports: [MatLabel, MatFormFieldModule, ReactiveFormsModule,NgIf,NgFor ,MatInputModule,MatIconModule],
  templateUrl: './unit-edit.component.html',
  styleUrl: './unit-edit.component.scss',
})
export class UnitEditComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: { id: number; subChapterId: number },
    private dialogRef: MatDialogRef<UnitEditComponent>,
    private unitService: UnitService,
    private toast: ToastrService,
    private s3Service: S3Service,
  
  ) {
    this.unitForm.get('subChapterId')?.setValue(Number(_data.id));
    this.id = _data.id;
  }
  id: number | undefined;
  destroy$ = new Subject<any>();
  questionGListChanges = new Subject<void>();
  unitForm = new FormGroup({
    title: new FormControl(''),
    details: new FormControl(''),
    subChapterId: new FormControl(),
    numOfShortQuestions: new FormControl(-1),
    numOfLongQuestions: new FormControl(-1),
  });

  ngOnInit(): void {
    this.unitService
      .getById(this._data.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((u) =>{ this.unitForm.patchValue(u)
        this.parseMarkdownLinks(u.details); // Parse markdown links on load
      });
  }


  parseMarkdownLinks(details: string) {
    const markdownRegex = /!\[.*?\]\((.*?)\)/g;
    this.parsedImageUrls = [];
    let match: RegExpExecArray | null;
    while ((match = markdownRegex.exec(details)) !== null) {
      this.parsedImageUrls.push(match[1]); // Extract each URL
    }
  }
  removeImagePreview(url: string,index: number) {
    this.parsedImageUrls.splice(index, 1); // Remove image URL from list
    const details = this.unitForm.get('details')?.value || '';
    const markdownRegex = /!\[.*?\]\((.*?)\)/g;
    
    if (confirm('Are you sure you want to delete?')) {
      // Extract the relative file path
      const baseUrl = 'https://yashi-labs.s3.ap-south-1.amazonaws.com/';
      const relativePath = url.replace(baseUrl, ''); // Removes base URL
  
      // Remove file from S3
      this.s3Service.removeFile(relativePath).subscribe(() => {
        console.log(`File ${relativePath} deleted from S3.`);
      });
    }
    let updatedDetails = '';
    let match: RegExpExecArray | null;
    let i = 0;
    // while ((match = markdownRegex.exec(details)) !== null) {
    //   if (i !== index) updatedDetails += `![image](${match[1]})\n`;
    //   i++;
    // }
  }
 onImageSelected(event: Event) {
    const el = event.target as HTMLInputElement;
    if (el && el.files) {
      const file = el.files[0];
      const fileSizeMB = file.size / (1024 * 1024); // Size in MB
      // if (fileSizeMB > 10) {
      //   this.toast.warning('Max file Size allowed is 10mb');
      //   return;
      // }
      this.convertToBlob(file, (blob) => {
        this.files.push(file); // Add the file to the files array
        const imageUrl = URL.createObjectURL(blob); // Generate local image URL
        this.imgUrls.update((urls) => [...urls, imageUrl]); // Update local preview URLs
        // Simulate S3 upload (replace with actual S3 upload logic)
       const s3Url = this.uploadToS3(file); 
        this.s3Urls.update((urls) => [...urls, s3Url]); // Update S3 URLs
      });      
    }
    
  }
  uploadToS3(file: File): string {
    // Replace this mock implementation with your S3 upload logic
    const s3BucketUrl = environment.s3Url + 'notes'+this.id;
    const uniqueFileName = `${this.id}-${file.name}`;
    return `${s3BucketUrl}${uniqueFileName}`;
  }
  removeImage(index: number) {
    this.imgUrls.update((urls) => {
      const updatedUrls = [...urls];
      updatedUrls.splice(index, 1);
      return updatedUrls;
    });
    this.s3Urls.update((urls) => {
      const updatedUrls = [...urls];
      updatedUrls.splice(index, 1);
      return updatedUrls;
    });
    this.files.splice(index, 1); // Remove the file at the same index
  }
  file: File | null = null;
  files: File[] = []; // Array to store files
  readonly imgFileUpload: Signal<ElementRef> = viewChild.required('imgUpload');
  readonly imgUrls: WritableSignal<string[]> = signal([]); // Array to store image URLs
  readonly imgUrl: WritableSignal<null | string> = signal(null);
  readonly s3Urls: WritableSignal<string[]> = signal([]); // Array to store S3 image URLs
  // selectedFile: File | null = null;
  s3BucketUrl!: string | '';
  parsedImageUrls: string[] = []; // To hold parsed image URLs
  fileUrl: string | null = null;

  openImgFileUpload() {
    this.imgFileUpload().nativeElement.click();
  }
  fileUpload(index: number) {
    // Replace spaces with %20 in the file name
    const fileName = this.files[index].name.replace(/ /g, '%20');
    
    this.s3Service.uploadFile(this.files[index], 'notes/' + this.id + '/' + fileName);
    this.s3BucketUrl = environment.s3Url + environment.s3Object + 'notes/' + this.id + '/' + fileName;
  }
  
  viewPrevFile(index: number) {
    this.fileUrl = environment.s3Url + environment.s3Object+ 'notes/'+this.id+'/'+ `${this.id}-${index}.png`;
    window.open(this.fileUrl!, '_blank');
  }
    convertToBlob = (file: File, cb: (blob: Blob) => void) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target == null) return;
      let blob: Blob;
      if (typeof event.target.result === 'string') {
        // Handle the case when event.target.result is a string
        blob = new Blob([event.target.result], { type: file.type });
      } else if (event.target.result instanceof ArrayBuffer) {
        // Handle the case when event.target.result is an ArrayBuffer
        blob = new Blob([event.target.result], { type: file.type });
      } else {
        // Handle the case when event.target.result is null or an unexpected type
        console.error(
          'Unexpected data type from FileReader:',
          typeof event.target.result
        );
        return;
      }
      cb(blob);
    };
    reader.readAsArrayBuffer(file);
  };
  submit() {
    if (!this.id) return;
    const unitF = this.unitForm.value;
    const unit: Unit = {
      title: unitF.title ?? '',
      details: unitF.details ?? '',
      subChapterId: unitF.subChapterId ?? 0,
      numOfLongQuestions: unitF.numOfLongQuestions ?? -1,
      numOfShortQuestions: unitF.numOfShortQuestions ?? -1,
    };
    this.unitService.update(this.id, unit).subscribe(() => {
      this.toast.success('Unit Updated Successfully !!');
      this.dialogRef.close();
    });
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}
