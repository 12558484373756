import { Component, ElementRef, OnInit, signal, Signal, viewChild, WritableSignal } from '@angular/core';
import { ActivatedRoute, Router, RouterLinkWithHref } from '@angular/router';
import { Subject, map, switchMap, tap } from 'rxjs';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonModule, JsonPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { SubChapter, SubChapterService } from 'src/app/subChapter.service';
import { MarkdownModule } from 'ngx-markdown';
import { S3Service } from 'src/app/s3.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-question-set-edit',
  templateUrl: './sub-chapter-edit.component.html',
  styleUrls: ['./sub-chapter-edit.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule,
    MarkdownModule,
  ],
})
export class SubChapterEditComponent implements OnInit {
  constructor(
    private subChapterService: SubChapterService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
        private s3Service: S3Service,
  ) {}
  id: number | undefined;
  destroy$ = new Subject<any>();
  questionGListChanges = new Subject<void>();
  questionSetForm = new FormGroup({
    title: new FormControl(''),
    details: new FormControl(''),
    numOfShortQuestions: new FormControl(-1),
    numOfLongQuestions: new FormControl(-1),
  });
  subChapter$: any;
  ngOnInit(): void {
    this.subChapter$ = this.route.params.pipe(
      map((params) => params['id']),
      tap((id) => (this.id = id)),
      switchMap((id) => this.subChapterService.getById(id)),
      tap((question) => this.questionSetForm.patchValue(question))
    );
  }
  goback() {
    history.back();
  }
  submit() {
    const question = this.questionSetForm.value;
    const questionG: SubChapter = {
      title: question.title ?? '',
      details: question.details ?? '',
      numOfLongQuestions: question.numOfLongQuestions ?? 0,
      numOfShortQuestions: question.numOfShortQuestions ?? 0,
    };
    this.subChapterService.update(this.id!, questionG).subscribe((res: any) => {
      this.toastrService.success('Sub Chapter Edited successfully');
    });
  }

}
