import { Component, OnInit, ViewChild, inject } from '@angular/core';
import {
  CoinTransaction,
  CoinTransactionService,
} from '../coin-transaction.service';
import {
  Observable,
  Subject,
  debounceTime,
  filter,
  map,
  merge,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { FilterDTO } from '../app.model';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { CdkTableDataSourceInput } from '@angular/cdk/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import {
  FormControl,
  FormGroup,
  NgModel,
  ReactiveFormsModule,
} from '@angular/forms';
import { User, UserService } from '../user.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { UtcToLocalPipe } from '../coin-transactions/utc-to-local.pipe';
import { MatButton } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { KeycloakService } from 'keycloak-angular';
import { getRoleFromKc } from '../Utils';

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [
    AsyncPipe,
    MatTableModule,
    UtcToLocalPipe,
    MatPaginator,
    MatFormField,
    MatSelect,
    MatOption,
    MatLabel,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInput,
    MatButton,
    MatCheckboxModule,
    NgIf,
  ],
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss',
})
export class UserListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  readonly transactionTypeCtrl = new FormControl('All');
  coins$!: Observable<CdkTableDataSourceInput<CoinTransaction>>;
  users$!: Observable<CdkTableDataSourceInput<User>>;

  readonly kc = inject(KeycloakService);
  readonly role = getRoleFromKc(this.kc);
  totalElements = 0;
  readonly displayedColumns = [
    'id',
    'email',
    'firstName',
    'lastName',
    'coins',
    'category',
    'phone',
    'createdTime',
    'callAttempted',
    'notes',
    'action',
  ];
  readonly userCtrl = new FormControl<string | null>(null);
  readonly userService = inject(UserService);
  readonly toast = inject(ToastrService);
  readonly search$ = new Subject<void>();
  usersForm!: FormGroup;
  user$!: Observable<User[]> | undefined;

  ngOnInit(): void {
    this.user$ = this.userCtrl?.valueChanges.pipe(
      debounceTime(500),
      filter((s) => !!s),
      switchMap((s) => {
        const filter: FilterDTO = {
          pageNumber: 1,
          pageSize: 10,
          search: `email:${s}*`,
        };
        return this.userService.filterData(filter).pipe(map((p) => p.elements));
      })
    );
    this.getUserData();
  }

  displayFn(user: any): string {
    return user && user.email ? user.email : '';
  }
  getUserData() {
    this.users$ = merge(this.paginator.page, this.search$.asObservable()).pipe(
      startWith({}),
      switchMap(() => {
        let search = '';
        const email = this.userCtrl.value;

        if (email && email.length > 0) {
          if (search?.length > 0) {
            search += ' AND ';
          }
          search += `email:${email}`;
        }
        const f: FilterDTO = {
          pageNumber: this.paginator.pageIndex + 1,
          pageSize: this.paginator.pageSize,
          search: search,
          sorts: [
            {
              key: 'createdTime',
              direction: 'DESC',
            },
          ],
        };
        return this.userService.filterData(f).pipe(
          tap((p) => (this.totalElements = p.totalElements)),
          map((p) => p.elements)
        );
      })
    );
  }
  deleteUser(id: string) {
    const y = confirm('Are you sure you want to delete the user?');
    if (y) {
      this.userService.delete(id).subscribe(() => {
        this.toast.success('deleted');
        this.search$.next();
      });
    }
  }
  update(id: string, callAttempted: boolean, called: boolean, notes: string) {
    this.userService.updateUserCall(id, callAttempted, called, notes);

    setTimeout(() => {
      this.getUserData();
    }, 100);
  }
}
