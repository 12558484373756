import { KeycloakService } from 'keycloak-angular';
import { Role } from './auth.guard';

export function trimObjectValues(obj: any) {
  if (typeof obj === 'object') {
    if (Array.isArray(obj)) {
      // If it's an array, recursively trim each element
      for (let i = 0; i < obj.length; i++) {
        obj[i] = trimObjectValues(obj[i]);
      }
    } else {
      // If it's an object, recursively trim each property
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          obj[key] = trimObjectValues(obj[key]);
        }
      }
    }
  } else if (typeof obj === 'string') {
    // If it's a string, trim it
    obj = obj.trim();
  }

  return obj;
}

export function getRoleFromKc(kc: KeycloakService): Role {
  const roles = kc.getUserRoles();
  if (roles.length === 0) return 'USER';
  if (kc.getUserRoles().includes('ADMIN')) return 'ADMIN';
  if (kc.getUserRoles().includes('EDITOR')) return 'EDITOR';
  if (kc.getUserRoles().includes('MARKETING')) return 'MARKETING';
  return 'USER';
}
