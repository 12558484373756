import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { Observable } from 'rxjs';

export type Role = 'ADMIN' | 'EDITOR' | 'USER' | 'MARKETING';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard implements CanLoad {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.authenticated;
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state?.url,
      });
    }

    // Get the roles required from the route.
    const requiredRoles = route.data['roles'];

    console.log(
      'Roles Required ',
      route.data['roles'],
      'Roles available',
      this.roles
    );
    // Allow the user to proceed if no additional roles are required to access the route.
    if (!Array.isArray(requiredRoles) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    let accessAllowed = false;
    for (const role of requiredRoles) {
      if (this.roles.includes(role)) {
        accessAllowed = true;
        break;
      }
    }
    if (!accessAllowed)
      if (
        confirm(
          'Not Allowed to access the route, No proper role.. View Console for more info.. Logout & try Again ?'
        ) === true
      ) {
        await this.keycloak.logout();
      }
    return accessAllowed;
  }
}
