<h1>Subscriptions</h1>

<div class="flex gap-4">
  <mat-form-field>
    <mat-label>Choose subscription type</mat-label>
    <mat-select [formControl]="subscriptionTypeCtrl">
      @for(item of ['ALL', 'BASIC', 'STANDARD', 'PREMIUM']; track item){
      <mat-option [value]="item"> {{ item }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field class="w-[300px]">
    <mat-label>User</mat-label>
    <input
      type="text"
      placeholder="Enter user email"
      matInput
      [formControl]="userCtrl"
      [matAutocomplete]="auto"
      class="min-w-[500px]"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      @for (user of users$ | async; track user.id) {
      <mat-option [value]="user">{{ user.email }}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
  <button (click)="search$.next()">Search</button>
  <button *ngIf="role === 'ADMIN'" class="bg-red-200 p-4 ml-20 h-fit w-fit" (click)="purchase()">
    Purchase
  </button>
</div>
<table
  mat-table
  [dataSource]="(subscriptions$ | async) ?? []"
  class="mat-elevation-z8"
>
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>Id.</th>
    <td mat-cell *matCellDef="let element">{{ element.id }}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="cost">
    <th mat-header-cell *matHeaderCellDef>Cost</th>
    <td mat-cell *matCellDef="let element">{{ element.cost }}</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="level">
    <th mat-header-cell *matHeaderCellDef>Level</th>
    <td mat-cell *matCellDef="let element">{{ element.level }}</td>
  </ng-container>
  <ng-container matColumnDef="validDays">
    <th mat-header-cell *matHeaderCellDef>Valid Days</th>
    <td mat-cell *matCellDef="let element">{{ element.validDays }}</td>
  </ng-container>

  <ng-container matColumnDef="startDate">
    <th mat-header-cell *matHeaderCellDef>Start Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element.startDate | utcToLocal }}
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  #paginator
  class="demo-paginator"
  [length]="totalElements"
  [pageSize]="20"
  aria-label="Select page"
>
</mat-paginator>
