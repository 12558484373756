<div
  class="font-poppins flex mx-auto text-xl md:text-2xl text-[#C44055] text-center my-6 w-fit"
>
  Add Unit
</div>

<form
  [formGroup]="unitForm"
  class="flex flex-col p-10 m-8 sm:flex-row [&div>input]:bg-[#E8F9F0] bg-[#295E84] rounded-xl gap-5"
>
  <div
    class="flex flex-col grow flex-wrap mx-auto text-gray-200 text-center gap-3 w-full"
  >
    <div class="flex flex-wrap"></div>
    <mat-form-field class="mt-8">
      <mat-label class="">Title</mat-label>
      <input
        matInput
        placeholder="Enter Title"
        formControlName="title"
        required
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Num of Short Questions</mat-label>
      <input
        matInput
        placeholder="Num of short questions"
        formControlName="numOfShortQuestions"
        required
        type="number"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Num of Long Questions</mat-label>
      <input
        matInput
        placeholder="Num of long questions"
        formControlName="numOfLongQuestions"
        required
        type="number"
      />
    </mat-form-field>

<input
#imgUpload
type="file"
accept="image/png, image/jpeg"
hidden
(change)="onImageSelected($event)"
/>
<button  (click)="openImgFileUpload()" class="text-black rounded-md mx-auto w-[200px] h-10 p-2 bg-white"
> select Photo</button
>
<div *ngIf="imgUrls().length > 0" class="bg-transparent">
<div *ngFor="let img of imgUrls(); let i = index" class="relative  border border-gray-300 m-2 flex flex-col gap-2">
  <div class=" h-40 w-96 place-items-center flex flex-row justify-between ">
    <img class="h-32 w-32 object-cover" [src]="img" alt="Uploaded Image" />
    <mat-icon
      class="absolute top-0 right-0 cursor-pointer bg-red-800 text-white rounded-full"
      (click)="removeImage(i)"
       >
      close
    </mat-icon>
    <p class="text-sm text-white mt-2">{{ s3BucketUrl }}</p>
  </div>
  <button class="text-black rounded-md mx-auto w-[200px] h-10 p-2 bg-white m-2 " (click)="fileUpload(i)">Upload Image</button>
</div>
</div>
    <mat-form-field>
      <mat-label class="">Details</mat-label>
      <textarea
        matInput
        placeholder="Enter Details"
        formControlName="details"
        required
      ></textarea>
    </mat-form-field>
    <div class="flex flex-wrap mx-auto gap-3">
      <button
        (click)="submit()"
        mat-raised-button
        color="primary"
        class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
      >
        Save
      </button>
      <a>
        <button
          mat-raised-button
          (click)="closeDialog()"
          color="primary"
          class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
        >
          Cancel
        </button>
      </a>
    </div>
  </div>
</form>
