@if(role === 'ADMIN'){
<a (click)="openCategoryAddDialog()" class="m-2">
  <button
    class="bg-[#04D9FF] text-white hover:bg-gray-300 focus:outline-none focus:bg-indigo-600 font-poppins text-[18px] md:text-xl px-5 py-2 rounded-lg"
  >
    Add Category
  </button>
</a>
}

<div class="grid grid-cols-2 w-fit gap-2 mx-auto">
  @for(c of categories();track c.id){
  <div
    class="max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow-md break-all"
  >
  <div class="flex flex-row justify-between">

    <div class="flex flex-col justify-between">

      <h2 class="mb-2 font-normal text-gray-900">{{ c.name }}</h2>
      <div class="flex flex-col gap-2 border-[2px]">
        <input
          class="hidden"
          #pdfUpload
          type="file"
          accept="application/pdf"
          (change)="onPdfSelected($event, c.id!)"
          multiple="false"
        />
        <button *ngIf="selectedPdf==null" class="p-2 border-[2px]" type="button" (click)="pdfUpload.click()">
          Upload PDF
        </button>
      
        <div *ngIf="selectedPdf && c.id === pdfId">
          <p>{{ selectedPdf!.name}}</p>
          <button class="p-2 border-[2px]" type="button" (click)="clearPdfSelection()">
            Clear PDF
          </button>
          <button class="p-2 border-[2px]" type="button" (click)="uploadPdf(c.name)">
            Upload PDF
          </button>
        </div>
        
  <div  class="flex items-center gap-2">
    <a class="border-[2px] p-2" href="https://yashi-labs.s3.ap-south-1.amazonaws.com/engineering_quiz/files/Syllabus+License/{{c.name}}.pdf" target="_blank">View PDF</a>
     <button class="p-2 border-[2px]" type="button" (click)="removePdf(c.name)">
      Remove
    </button>
    </div>
      </div>
      
    </div>
<div class="flex flex-col gap-2 relative">

  <img 
  class="h-[95px] object-cover"
          src="https://yashi-labs.s3.ap-south-1.amazonaws.com/engineering_quiz/files/Assets/faculty/{{c.name}}.png"
          alt="image"
      />   
      <mat-icon
      class="absolute top-0 right-0 cursor-pointer bg-red-800 text-white rounded-full"
      (click)="removeImage(c.name)"
    
      >
 close
    </mat-icon>
  <div class="flex flex-col gap-2">
    <input
      class="hidden"
      #fileUpload
      type="file"
      accept="image/png, image/jpeg"
      (change)="onImageSelected($event, c.id!)"
      multiple="false"
    />
    @if(selectedFile==null){

      <button class=" p-2" type="button" (click)="fileUpload.click()">
        Upload File
      </button>
    }
    @if (c.id==id) {
    @if (selectedFile) {
    <button class=" p-2" type="button" (click)="selectedFile = null">
      Clear File
    </button>
   
    <img class="h-32 w-32 object-cover" [src]="imgUrl()" alt="Uploaded Image" />
    <button class="p-2" type="button" (click)="fileUploads(c.name)">
      Upload File
    </button>
    }}
    <!-- <app-file-upload formControlName="file"></app-file-upload> -->
  </div>



</div>
  </div>
  <div class="border-b-2"></div>
    @if(c.details){
    <p class="text-gray-700 text-sm">{{ c.details | json }}</p>
    }

    <div class="mt-4 flex flex-col justify-between">
      <span class="text-gray-600">ID: {{ c.id }}</span>
      <div class="flex flex-wrap gap-1">
        @if(c.leaf && role === 'ADMIN'){
        <button
          (click)="openCategoryAddDialog(c.id)"
          class="bg-sky-500 text-white px-4 py-2 rounded hover:bg-sky-600 transition duration-200"
        >
          Add Category Inside
        </button>
        }
        <a
          class="bg-green-500 text-white px-4 py-2 mx-2 rounded hover:bg-green-600 transition duration-200"
          [routerLink]="c.leaf ? ['/home/chapters', c.id] : ['/home/category']"
          [queryParams]="
            !c.leaf
              ? {
                  parentId: c.id
                }
              : {}
          "
        >
          <button>{{ c.leaf ? "View Chapters" : "Open" }}</button>
        </a>
        <a
        class="bg-green-500 text-white px-4 py-2 mx-2 rounded hover:bg-green-600 transition duration-200"
        [routerLink]="['/home/forum', c.id]"
       
      >
        <button>View Messages</button>
      </a>
        @if(role === 'ADMIN'){
        <button
          (click)="openCategoryEditDialog(c.id)"
          class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200"
        >
          Edit
        </button>
        <button
          (click)="deleteCategory(c.id)"
          class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-200 ml-2"
        >
          Delete
        </button>
        }
      </div>
    </div>
  </div>
  }
</div>
