


import { CdkTableDataSourceInput } from '@angular/cdk/table';
import { Component, DestroyRef, ViewChild, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteModule,
} from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatTable, MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import {
  Observable,
  Subject,
  debounceTime,
  filter,
  map,
  merge,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { User, UserService } from '../user.service';
import { Subscription, SubscriptionService } from '../subscription.service';
import { FilterDTO } from '../app.model';
import { UtcToLocalPipe } from '../coin-transactions/utc-to-local.pipe';
import { AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserActivity } from './user-activity.service';

@Component({
  selector: 'app-user-activity',
  standalone: true,
  imports: [
    RouterLink,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatTableModule,
    MatPaginator,
    UtcToLocalPipe,
    AsyncPipe,
    MatInputModule,
    FormsModule,
  ],
  templateUrl: './user-activity.component.html',
  styleUrl: './user-activity.component.scss'

})
export class UserActivityComponent {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  readonly userActivityService = inject(UserActivity);
  readonly subscriptionTypeCtrl = new FormControl('ALL');
  readonly toast = inject(ToastrService);
  userActivity$!: Observable<CdkTableDataSourceInput<UserActivity>>;
  totalElements = 0;
  readonly displayedColumns = [
    'userId',
    'email',
    'activityType',
    'date',
    'ip',
    'device',
    'details',
  ];
  readonly userCtrl = new FormControl<User | null>(null);
  readonly activityCtrl = new FormControl<User | null>(null);

  readonly userService = inject(UserService);
  readonly search$ = new Subject<void>();
  readonly destroyRef = inject(DestroyRef);
  users$!: Observable<User[]> | undefined;
  ngOnInit(): void {
    this.users$ = this.userCtrl?.valueChanges.pipe(
      debounceTime(500),
      filter((s) => !!s),
      switchMap((s) => {
        const filter: FilterDTO = {
          pageNumber: 1,
          pageSize: 10,
          search: `email:${s}*`,
        };
        return this.userService.filterData(filter).pipe(map((p) => p.elements));
      })
    );
    this.userActivity$ = merge(
      this.paginator.page,
      this.search$.asObservable()
    ).pipe(
      startWith({}),
      switchMap(() => {
        let search = '';
        const user = this.userCtrl.value;
        const activity = this.activityCtrl.value;
        if(activity){
          if (search?.length > 0) {
            search += ' AND ';
          }
          search +=`id.activityType:${activity}`
        }
        
        if (user) {
          if (search?.length > 0) {
            search += ' AND ';
          }
        search += `id.user.email:${user}`;

        }
        const f: FilterDTO = {
          pageNumber: this.paginator.pageIndex + 1,
          pageSize: this.paginator.pageSize,
          search: search,    
          sorts: [
            {
              key: 'id.time',
              direction: 'DESC',
            },
          ], 
        };
        return this.userActivityService.filterData(f).pipe(
          tap((p) => (this.totalElements = p.totalElements)),
          map((p) => p.elements)
        );
      })
    );
  }

  displayFn(user: any): string {
  return user && user.email ? user.email : '';
  }

  purchase() {
    const level = this.subscriptionTypeCtrl.value;
    const user = this.userCtrl.value;
    if (!level || level === 'ALL') {
      this.toast.error('Please select another subscription package');
      return;
    }
    if (!user) {
      this.toast.error('Please select user');
      return;
    }

    const confirmed = confirm(
      'Are you sure you want to purchase the subscription for user'
    );
    if (!confirmed) return;

  
  }
}
